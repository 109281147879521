import * as React from "react"
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi"
import { graphql } from "gatsby"
import { isMobile } from "react-device-detect"

import { Layout, ContactForm, GoogleMap, Seo } from "../../components"

const ContactPage = ({ data }) => {
  const { frontmatter: shared } = data.shared
  const { frontmatter: content } = data.content

  const finalBackground =
    isMobile && content.backgroundMobile !== ""
      ? content.backgroundMobile
      : content.background

  return (
    <Layout footerWithCta={false}>
      <Seo title="Kontakt" />

      <div
        className="rn-page-title-area pt--120 pb--190 bg_image"
        style={{ backgroundImage: `url(${finalBackground})` }}
        data-black-overlay="6"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">{content.title}</h2>
                <p>{content.subtitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row align-items-stretch">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address bg_color--1">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4 className="title">Skontaktuj się telefonicznie</h4>
                  <p>
                    <a href={`tel:${shared.contact.phone}`}>
                      {shared.contact.phone}
                    </a>
                  </p>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
              <div className="rn-address bg_color--1">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Adres email</h4>
                  <p>
                    <a href={`mailto:${shared.contact.email}`}>
                      {shared.contact.email}
                    </a>
                  </p>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address bg_color--1">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4 className="title">Lokalizacja</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: shared.contact.location,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rn-contact-page ptb--120 bg_color--1" id="formularz">
        <ContactForm
          title={content.contactTitle}
          content={content.contactContent}
          image={content.image}
        />
      </div>

      <div className="rn-contact-map-area position-relative">
        <div style={{ height: "650px", width: "100%" }}>
          <GoogleMap
            lat={Number(shared.contact.map.lat)}
            lng={Number(shared.contact.map.lng)}
          />
        </div>
      </div>
    </Layout>
  )
}

export const guery = graphql`
  {
    shared: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "shared" } }
    ) {
      frontmatter {
        contact {
          email
          phone
          location
          map {
            lat
            lng
          }
        }
      }
    }

    content: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "contact" } }
    ) {
      frontmatter {
        background
        backgroundMobile
        image
        title
        subtitle
        contactTitle
        contactContent
      }
    }
  }
`

export default ContactPage
